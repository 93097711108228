<template>
  <area-container>
    <area-nav :links="links"></area-nav>
    <area-content>
      <router-view></router-view>
    </area-content>
  </area-container>
</template>

<script>
export default {
  
  data () {
    return {
      links: [
        { route: "support.Tickets", name: this.$tk("SupportLayout.Tickets") },
        { route: "support.Templates", name: this.$tk("SupportLayout.Templates") },
      ]
    }
  }
  
}
</script>
